import { For, Show } from "solid-js";
import {
  A,
  cache,
  createAsync,
  useSearchParams,
  type RouteDefinition,
  type RouteSectionProps,
} from "@solidjs/router";
import { AdjustmentsHorizontal, Pen, Plus } from "~/components/icons";
import { Pagination } from "~/components/Pagination";
import SuspenseWrap from "~/components/SuspenseWrap";
import { currentUserIdCache } from "~/services";
import { query } from "~/repos/article";

const limit = 10;

const getArticles = cache(async (q: string, page: number) => {
  "use server";
  const userId = await currentUserIdCache();
  if (!userId) throw new Error("Blog not found");

  return await query({ query: q, authorId: userId }, { size: limit, page });
}, "dash/articles");

export const route = {
  load({ location }) {
    void getArticles(location.query.q || "", +location.query.page || 1);
  },
} satisfies RouteDefinition;

export default function DashArticles(props: RouteSectionProps) {
  const query = () => props.location.query.q;
  const page = () => +props.location.query.page || 1;
  const data = createAsync(() => getArticles(query(), page()), {
    deferStream: true,
  });

  const [, setSearchParams] = useSearchParams();

  const goToNextPage = () => {
    setSearchParams({ page: data()!.page + 1 });
  };
  const goToPrevPage = () => {
    setSearchParams({ page: data()!.page - 1 });
  };

  return (
    <SuspenseWrap class="p-4 gap-2 flex flex-col">
      <Show when={data()}>
        <div class="flex">
          <A
            href="create"
            class="grow flex border-2 border-dashed border-gray-400 justify-center items-center h-16"
          >
            <Plus class="w-6 h-6" />
          </A>
          <div></div>
        </div>
        <div class="divide-y divide-dotted divide-gray-800 mb-4">
          <For each={data()?.hits}>
            {(article) => (
              <div class="flex flex-row">
                <A
                  class="grow py-1 pr-2 block prose dark:prose-invert max-w-none"
                  href={article.id}
                >
                  <h2>{article.title}</h2>
                  <p>{article.abstract}</p>
                </A>
                <div class="flex ">
                  <A
                    href={`${article.id}/edit`}
                    class="w-20 h-20 text-center flex justify-center items-center"
                  >
                    <Pen class="w-6 h-6  " />
                  </A>
                  <A
                    href={`${article.id}/setting`}
                    class="pl-7 h-20 text-center flex justify-center items-center"
                  >
                    <AdjustmentsHorizontal size={6} />
                  </A>
                </div>
              </div>
            )}
          </For>
        </div>
        <Pagination
          totalPage={() => data()!.totalPages}
          page={() => data()!.page}
          goPrev={goToPrevPage}
          goNext={goToNextPage}
        />
      </Show>
    </SuspenseWrap>
  );
}
